import axios from 'axios';
import store from 'store/store';

let instance = null;
export const baseUrl = process.env.NODE_ENV !== 'production' ? window.location.protocol + '//local.citistarsuccess.com/' : '/';

const defaultAxios = async () => {
  if (!instance) {
    try {
      const response = await axios.get(baseUrl+'rest/session/token', {withCredentials: true});
      const token = response.data;
      instance = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
          'X-CSRF-Token': token
        }
      });
      instance.interceptors.request.use(config => {
        if (!config.params) {
          config.params = {
            _format: 'json'
          };
        }
        else if (!config.params._format) {
          config.params._format = 'json';
        }
        return config;
      })
      instance.CancelToken = axios.CancelToken;
      instance.isCancel = axios.isCancel;
    }
    catch (error) {
      console.error(error);
    }
  }
  if (store.getState().sess && store.getState().sess.csrf_token) {
    instance.defaults.headers['X-CSRF-Token'] = store.getState().sess.csrf_token;
  }

  return instance
}

export default defaultAxios