export const setSess = sess => ({ type: 'SET_SESS', sess });

export const setUser = user => ({ type: 'SET_USER', user });
export const resetUser = () => ({ type: 'RESET_USER' });

export const setAdvisors = advisors => ({ type: 'SET_ADVISORS', advisors });
export const resetAdvisors = keepSearch => ({ type: 'RESET_ADVISORS', keepSearch });

export const setCorporations = corporations => ({ type: 'SET_CORPORATIONS', corporations });
export const resetCorporations = keepSearch => ({ type: 'RESET_CORPORATIONS', keepSearch });

export const setSales = sales => ({ type: 'SET_SALES', sales });
export const resetSales = keepSearch => ({ type: 'RESET_SALES', keepSearch });

export const setTeam = team => ({ type: 'SET_TEAM', team });
export const resetTeam = () => ({ type: 'RESET_TEAM' });

export const setPerformance = performance => ({ type: 'SET_PERFORMANCE', performance });
export const resetPerformance = keepSearch => ({ type: 'RESET_PERFORMANCE', keepSearch });

export const setMyPerformance = myPerformance => ({ type: 'SET_MY_PERFORMANCE', myPerformance });
export const resetMyPerformance = keepSearch => ({ type: 'RESET_MY_PERFORMANCE', keepSearch });

export const setDoc = doc => ({ type: 'SET_DOC', doc });
export const resetDoc = keepSearch => ({ type: 'RESET_DOC', keepSearch });

export const setMyDoc = doc => ({ type: 'SET_MY_DOC', doc });
export const resetMyDoc = keepSearch => ({ type: 'RESET_MY_DOC', keepSearch });

export const setTeams = teams => ({ type: 'SET_TEAMS', teams });
export const resetTeams = () => ({ type: 'RESET_TEAMS' });

export const setRates = rates => ({ type: 'SET_RATES', rates });
export const resetRates = () => ({ type: 'RESET_RATES' });

export const setAllFYC = allFyc => ({ type: 'SET_ALL_FYC', allFyc });
export const resetAllFYC = keepSearch => ({ type: 'RESET_ALL_FYC', keepSearch });

export const setTeamAllFYC = teamAllFYC => ({ type: 'SET_TEAM_ALL_FYC', teamAllFYC });
export const resetTeamAllFYC = keepSearch => ({ type: 'RESET_TEAM_ALL_FYC', keepSearch });

export const setMyAllFYC = myAllFYC => ({ type: 'SET_MY_ALL_FYC', myAllFYC });
export const resetMyAllFYC = keepSearch => ({ type: 'RESET_MY_ALL_FYC', keepSearch });

export const setAllFYCSummary = allFycSummary => ({ type: 'SET_ALL_FYC_SUMMARY', allFycSummary });
export const resetAllFYCSummary = keepSearch => ({ type: 'RESET_ALL_FYC_SUMMARY', keepSearch });

export const setFYC = fyc => ({ type: 'SET_FYC', fyc });
export const resetFYC = keepSearch => ({ type: 'RESET_FYC', keepSearch });

export const setTeamFYC = teamFYC => ({ type: 'SET_TEAM_FYC', teamFYC });
export const resetTeamFYC = keepSearch => ({ type: 'RESET_TEAM_FYC', keepSearch });

export const setMyFYC = myFYC => ({ type: 'SET_MY_FYC', myFYC });
export const resetMyFYC = keepSearch => ({ type: 'RESET_MY_FYC', keepSearch });

export const setFYCSummary = fycSummary => ({ type: 'SET_FYC_SUMMARY', fycSummary });
export const resetFYCSummary = keepSearch => ({ type: 'RESET_FYC_SUMMARY', keepSearch });

export const setBonuses = bonuses => ({ type: 'SET_BONUSES', bonuses });
export const resetBonuses = keepSearch => ({ type: 'RESET_BONUSES', keepSearch });

export const setTeamBonuses = teamBonuses => ({ type: 'SET_TEAM_BONUSES', teamBonuses });
export const resetTeamBonuses = keepSearch => ({ type: 'RESET_TEAM_BONUSES', keepSearch });

export const setPayments = payments => ({ type: 'SET_PAYMENTS', payments });
export const resetPayments = keepSearch => ({ type: 'RESET_PAYMENTS', keepSearch });

export const setReferral = referral => ({ type: 'SET_REFERRAL', referral });
export const resetReferral = keepSearch => ({ type: 'RESET_REFERRAL', keepSearch });

export const setMyReferral = myReferral => ({ type: 'SET_MY_REFERRAL', myReferral });
export const resetMyReferral = keepSearch => ({ type: 'RESET_MY_REFERRAL', keepSearch });

export const setTeamApplications = teamApplications => ({ type: 'SET_TEAM_APPLICATIONS', teamApplications });
export const resetTeamApplications = keepSearch => ({ type: 'RESET_TEAM_APPLICATIONS', keepSearch });