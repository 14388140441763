import React, { useState, useEffect, Suspense, lazy } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import ajax from "ajax";
import { connect } from 'react-redux';
import { setSess } from 'store/actions/appActions';

// core components
// import LoggedIn from "layouts/LoggedIn";
// import LoggedOut from "layouts/LoggedOut";
const LoggedIn = lazy(() => import('layouts/LoggedIn'));
const LoggedOut = lazy(() => import('layouts/LoggedOut'));


const hist = createBrowserHistory({ basename: '/app' });

const mapStateToProps = ({ sess }) => {
  return { sess }
}

const actionCreators = {
  setSess
}

const App = ({ sess, setSess }) => {
  if (window.location.pathname === '/app/index.html') {
    let queryString = hist.location.search;
    const query = new URLSearchParams(queryString);
    const appPath = query.get('app_path');
    if (appPath) {
      queryString = queryString.replace('app_path='+encodeURIComponent(appPath), '').replace('?&', '?');
      const url = appPath + (queryString === '?' ? '' : queryString) + hist.location.hash;
      hist.replace(url, hist.location.state)
    }
  }

  const [timestamp, setTimestamp] = useState(0);

  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getLoginStatus = async () => {
      try {
        setLoading(true);
        const axios = await ajax();
        const response = await axios.get('user/login_status');
        const status = response.data;
        setTimestamp(now);
        setLoginStatus(status);
        if (status && !sess.current_user) {
          const userSess = await axios.get('session');
          setSess(userSess.data);
        }
      }
      catch (error) {
        console.error(error);
        setLoginStatus(false);
        setTimestamp(0);
      }
      if (sess.forceCheck) {
        const newSess = {...sess};
        delete newSess.forceCheck;
        setSess(newSess);
      }
      setLoading(false);
    }

    const now = Date.now();
    (now - timestamp > 5000 || sess.forceCheck) && !loading && !sess.noChecking && getLoginStatus();
  }, [sess, setSess, timestamp, loading]);

  return (
    <Router history={hist}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Suspense fallback={<CircularProgress />}>
          {loginStatus ? <LoggedIn /> : <LoggedOut />}
        </Suspense>
      )}
    </Router>
  );
}

export default connect(mapStateToProps, actionCreators)(App)