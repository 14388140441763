/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';
import 'core-js/features/object/values';
import 'core-js/features/object/entries';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import App from "components/App/App";
import store from 'store/store';

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
