import { combineReducers } from 'redux'

// Reusable utility functions
// function updateObject(oldObject, newValues) {
//   return Object.assign({}, oldObject, newValues)
// }

const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

/**
 * User
 **/
const setSess = (sessState, action) => {
  if (action.sess) {
    sessState = action.sess;
  }
  return sessState
}

// User Reducer
const sessReducer = createReducer({}, {
  SET_SESS: setSess
})

const setUser = (userState, action) => {
  if (action.user) {
    userState = action.user;
  }
  return userState
}

const resetUser = (userState) => {
  userState = null;
  return userState
}

// User Reducer
const userReducer = createReducer(null, {
  SET_USER: setUser,
  RESET_USER: resetUser,
})

/**
 * Advisor
 **/
const initAdvisors = {
  total: null,
  list: [],
  search: {
    page: 0,
    perPage: 10,
    order: 'asc',
    orderBy: 'uid',
    name: null,
    email: '',
    phone: '',
    corpName: '',
    teamName: '',
    brokerCode: '',
    createdBy: null,
    createdFromDate: null,
    createdToDate: null,
  }
}

const setAdvisors = (advisorsState, action) => {
  if (action.advisors) {
    advisorsState = action.advisors;
  }
  return advisorsState
}

const resetAdvisors = (advisorsState, action) => {
  const search = action.keepSearch ? advisorsState.search : initAdvisors.search;
  advisorsState = {...initAdvisors, search};
  return advisorsState
}

// Advisors Reducer
const advisorsReducer = createReducer(initAdvisors, {
  SET_ADVISORS: setAdvisors,
  RESET_ADVISORS: resetAdvisors
})

/**
 * Corporations
 **/
const initCorporations = {
  total: null,
  list: [],
  search: {
    page: 0,
    perPage: 10,
    name: '',
    brokerCode: '',
  }
}

const setCorporations = (corporationsState, action) => {
  if (action.corporations) {
    if (!action.corporations.search) {
      action.corporations.search = initCorporations.search
    }
    corporationsState = action.corporations;
  }
  return corporationsState
}

const resetCorporations = (corporationsState, action) => {
  const search = action.keepSearch ? corporationsState.search : initCorporations.search;
  corporationsState = {...initCorporations, search};
  return corporationsState
}

// Corporations Reducer
const corporationsReducer = createReducer(initCorporations, {
  SET_CORPORATIONS: setCorporations,
  RESET_CORPORATIONS: resetCorporations,
})

/**
 * Sales
 **/
const initSales = {
  total: null,
  list: [],
  premium_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'premium',
    groupBy: '',
    date: 'this_year',
    from: null,
    to: null,
    insurer: '',
    advisor: null,
    policyNumber: '',
    product: '',
    status: [],
    statusExclude: [],
    insured: '',
    corpName: '',
    brokerCode: '',
    teamName: ''
  }
}

const setSales = (salesState, action) => {
  if (action.sales) {
    salesState = action.sales;
  }
  return salesState
}

const resetSales = (salesState, action) => {
  const search = action.keepSearch ? salesState.search : initSales.search;
  salesState = {...initSales, search};
  return salesState
}

// Sales Reducer
const salesReducer = createReducer(initSales, {
  SET_SALES: setSales,
  RESET_SALES: resetSales,
})

/**
 * Team
 **/
const initTeam = {
  tree: [],
  members: null,
  teamleaders: []
}

const setTeam = (teamState, action) => {
  if (action.team) {
    teamState = action.team;
  }
  return teamState
}

const resetTeam = (teamState) => {
  teamState = initTeam;
  return teamState
}

// Team Reducer
const teamReducer = createReducer(initTeam, {
  SET_TEAM: setTeam,
  RESET_TEAM: resetTeam,
})

/**
 * Performance
 **/
const initPerformance = {
  total: null,
  list: [],
  premium_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'premium',
    groupBy: '',
    date: 'this_year',
    from: null,
    to: null,
    insurer: '',
    advisor: null,
    policyNumber: '',
    product: '',
    status: [],
    statusExclude: [],
    corpName: '',
    brokerCode: '',
    teamName: ''
  }
}

const setPerformance = (performanceState, action) => {
  if (action.performance) {
    performanceState = action.performance;
  }
  return performanceState
}

const resetPerformance= (performanceState, action) => {
  const search = action.keepSearch ? performanceState.search : initPerformance.search;
  performanceState = {...initPerformance, search};
  return performanceState
}

// Performance Reducer
const performanceReducer = createReducer(initPerformance, {
  SET_PERFORMANCE: setPerformance,
  RESET_PERFORMANCE: resetPerformance,
})

/**
 * My Performance
 **/
const initMyPerformance = {
  total: null,
  list: [],
  premium_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'from',
    groupBy: '',
    date: 'this_year',
    from: null,
    to: null,
    insurer: '',
    policyNumber: '',
    product: '',
    status: [],
    statusExclude: [],
    insured: '',
  }
}

const setMyPerformance = (myPerformanceState, action) => {
  if (action.myPerformance) {
    myPerformanceState = action.myPerformance;
  }
  return myPerformanceState
}

const resetMyPerformance= (myPerformanceState, action) => {
  const search = action.keepSearch ? myPerformanceState.search : initMyPerformance.search;
  myPerformanceState = {...initMyPerformance, search};
  return myPerformanceState
}

// My Performance Reducer
const myPerformanceReducer = createReducer(initMyPerformance, {
  SET_MY_PERFORMANCE: setMyPerformance,
  RESET_MY_PERFORMANCE: resetMyPerformance,
})

/**
 * Doc
 **/
const initDoc = {
  total: null,
  list: [],
  search: {
    page: 0,
    perPage: 25,
  }
};

const setDoc = (docState, action) => {
  if (action.doc) {
    docState = action.doc;
  }
  return docState
}

const resetDoc = (docState, action) => {
  const search = action.keepSearch ? docState.search : initDoc.search;
  docState = {...initDoc, search};
  return docState
}

// Doc Reducer
const docReducer = createReducer(initDoc, {
  SET_DOC: setDoc,
  RESET_DOC: resetDoc,
})

const setMyDoc = (docState, action) => {
  if (action.doc) {
    docState = action.doc;
  }
  return docState
}

const resetMyDoc = (docState, action) => {
  const search = action.keepSearch ? docState.search : initDoc.search;
  docState = {...initDoc, search};
  return docState
}

// My Doc Reducer
const myDocReducer = createReducer(initDoc, {
  SET_MY_DOC: setMyDoc,
  RESET_MY_DOC: resetMyDoc,
})

/**
 * Teams
 **/
const initTeams = {
  tree: [],
  members: null,
  teamleaders: []
}

const setTeams = (teamsState, action) => {
  if (action.teams) {
    teamsState = action.teams;
  }
  return teamsState
}

const resetTeams = (teamsState) => {
  teamsState = initTeams;
  return teamsState
}

// Teams Reducer
const teamsReducer = createReducer(initTeams, {
  SET_TEAMS: setTeams,
  RESET_TEAMS: resetTeams,
})

/**
 * Rates
 **/
const initRates = null;

const setRates = (ratesState, action) => {
  if (action.rates) {
    ratesState = action.rates;
  }
  return ratesState
}

const resetRates = (ratesState) => {
  ratesState = initRates;
  return ratesState
}

// Rates Reducer
const ratesReducer = createReducer(initRates, {
  SET_RATES: setRates,
  RESET_RATES: resetRates,
})

/**
 * All FYC
 **/
const initAllFYC = {
  total: null,
  list: [],
  all_fyc_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'all_fyc',
    year: '',
    period: '',
    insurer: '',
    product: '',
    advisor: null,
    brokerCode: '',
    corpName: '',
    teamName: ''
  }
}

const setAllFYC = (allFycState, action) => {
  if (action.allFyc) {
    allFycState = action.allFyc;
  }
  return allFycState
}

const resetAllFYC = (allFycState, action) => {
  const search = action.keepSearch ? allFycState.search : initAllFYC.search;
  allFycState = {...initAllFYC, search};
  return allFycState
}

// FYC Reducer
const allFycReducer = createReducer(initAllFYC, {
  SET_ALL_FYC: setAllFYC,
  RESET_ALL_FYC: resetAllFYC
})

/**
 * Team All FYC
 **/
const initTeamAllFYC = {
  total: null,
  list: [],
  all_fyc_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'all_fyc',
    year: '',
    period: '',
    insurer: '',
    product: '',
    advisor: null,
    brokerCode: '',
    corpName: '',
    teamName: ''
  }
}

const setTeamAllFYC = (teamAllFYCState, action) => {
  if (action.teamAllFYC) {
    teamAllFYCState = action.teamAllFYC;
  }
  return teamAllFYCState
}

const resetTeamAllFYC = (teamAllFYCState, action) => {
  const search = action.keepSearch ? teamAllFYCState.search : initTeamAllFYC.search;
  teamAllFYCState = {...initTeamAllFYC, search};
  return teamAllFYCState
}

// Team FYC Reducer
const teamAllFYCReducer = createReducer(initTeamAllFYC, {
  SET_TEAM_ALL_FYC: setTeamAllFYC,
  RESET_TEAM_ALL_FYC: resetTeamAllFYC,
})

/**
 * My All FYC
 **/
const initMyAllFYC = {
  total: null,
  list: [],
  all_fyc_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'all_fyc',
    year: '',
    period: '',
    insurer: '',
    brokerCode: '',
  }
}

const setMyAllFYC = (myAllFYCState, action) => {
  if (action.myAllFYC) {
    myAllFYCState = action.myAllFYC;
  }
  return myAllFYCState
}

const resetMyAllFYC = (myAllFYCState, action) => {
  const search = action.keepSearch ? myAllFYCState.search : initMyAllFYC.search;
  myAllFYCState = {...initMyAllFYC, search};
  return myAllFYCState
}

// My FYC Reducer
const myAllFYCReducer = createReducer(initMyAllFYC, {
  SET_MY_ALL_FYC: setMyAllFYC,
  RESET_MY_ALL_FYC: resetMyAllFYC,
})

/**
 * FYC Summary
 **/
const initAllFYCSummary = {
  total: null,
  list: [],
  all_fyc_total: 0,
  headers: [],
  header_labels: [],
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'total',
    year: '',
    advisor: null,
    brokerCode: '',
    teamName: ''
  }
}

const setAllFYCSummary = (allFycSummaryState, action) => {
  if (action.allFycSummary) {
    allFycSummaryState = action.allFycSummary;
  }
  return allFycSummaryState
}

const resetAllFYCSummary = (allFycSummaryState, action) => {
  const search = action.keepSearch ? allFycSummaryState.search : initAllFYCSummary.search;
  allFycSummaryState = {...initAllFYCSummary, search};
  return allFycSummaryState
}

// FYC Summary Reducer
const allFycSummaryReducer = createReducer(initAllFYCSummary, {
  SET_ALL_FYC_SUMMARY: setAllFYCSummary,
  RESET_ALL_FYC_SUMMARY: resetAllFYCSummary
})


/**
 * FYC
 **/
const initFYC = {
  total: null,
  list: [],
  fyc_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'fyc',
    year: '',
    period: '',
    insurer: '',
    product: '',
    advisor: null,
    brokerCode: '',
    corpName: '',
    teamName: ''
  }
}

const setFYC = (fycState, action) => {
  if (action.fyc) {
    fycState = action.fyc;
  }
  return fycState
}

const resetFYC = (fycState, action) => {
  const search = action.keepSearch ? fycState.search : initFYC.search;
  fycState = {...initFYC, search};
  return fycState
}

// FYC Reducer
const fycReducer = createReducer(initFYC, {
  SET_FYC: setFYC,
  RESET_FYC: resetFYC
})

/**
 * Team FYC
 **/
const initTeamFYC = {
  total: null,
  list: [],
  fyc_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'fyc',
    year: '',
    period: '',
    insurer: '',
    product: '',
    advisor: null,
    brokerCode: '',
    corpName: '',
    teamName: ''
  }
}

const setTeamFYC = (teamFYCState, action) => {
  if (action.teamFYC) {
    teamFYCState = action.teamFYC;
  }
  return teamFYCState
}

const resetTeamFYC = (teamFYCState, action) => {
  const search = action.keepSearch ? teamFYCState.search : initTeamFYC.search;
  teamFYCState = {...initTeamFYC, search};
  return teamFYCState
}

// Team FYC Reducer
const teamFYCReducer = createReducer(initTeamFYC, {
  SET_TEAM_FYC: setTeamFYC,
  RESET_TEAM_FYC: resetTeamFYC,
})

/**
 * My FYC
 **/
const initMyFYC = {
  total: null,
  list: [],
  fyc_total: 0,
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'fyc',
    year: '',
    period: '',
    insurer: '',
    brokerCode: '',
  }
}

const setMyFYC = (myFYCState, action) => {
  if (action.myFYC) {
    myFYCState = action.myFYC;
  }
  return myFYCState
}

const resetMyFYC = (myFYCState, action) => {
  const search = action.keepSearch ? myFYCState.search : initMyFYC.search;
  myFYCState = {...initMyFYC, search};
  return myFYCState
}

// My FYC Reducer
const myFYCReducer = createReducer(initMyFYC, {
  SET_MY_FYC: setMyFYC,
  RESET_MY_FYC: resetMyFYC,
})

/**
 * FYC Summary
 **/
const initFYCSummary = {
  total: null,
  list: [],
  fyc_total: 0,
  headers: [],
  header_labels: [],
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'total',
    year: '',
    advisor: null,
    brokerCode: '',
    teamName: ''
  }
}

const setFYCSummary = (fycSummaryState, action) => {
  if (action.fycSummary) {
    fycSummaryState = action.fycSummary;
  }
  return fycSummaryState
}

const resetFYCSummary = (fycSummaryState, action) => {
  const search = action.keepSearch ? fycSummaryState.search : initFYCSummary.search;
  fycSummaryState = {...initFYCSummary, search};
  return fycSummaryState
}

// FYC Summary Reducer
const fycSummaryReducer = createReducer(initFYCSummary, {
  SET_FYC_SUMMARY: setFYCSummary,
  RESET_FYC_SUMMARY: resetFYCSummary
})

/**
 * Bonuses
 **/
const initBonuses = {
  total: null,
  list: [],
  bonuses_total: 0,
  search: {
    page: 0,
    perPage: 10,
    // fid: '',
    year: '',
    teamName: '',
    // period: '',
    advisor: null,
    brokerCode: '',
  }
}

const setBonuses = (bonusesState, action) => {
  if (action.bonuses) {
    bonusesState = action.bonuses;
  }
  return bonusesState
}

const resetBonuses = (bonusesState, action) => {
  const search = action.keepSearch ? bonusesState.search : initBonuses.search;
  bonusesState = {...initBonuses, search};
  return bonusesState
}

// Bonuses Reducer
const bonusesReducer = createReducer(initBonuses, {
  SET_BONUSES: setBonuses,
  RESET_BONUSES: resetBonuses,
})

/**
 * Team Bonuses
 **/
const initTeamBonuses = {
  total: null,
  list: [],
  bonuses_total: 0,
  search: {
    page: 0,
    perPage: 10,
    year: '',
    advisor: null,
    brokerCode: '',
  }
}

const setTeamBonuses = (teamBonusesState, action) => {
  if (action.teamBonuses) {
    teamBonusesState = action.teamBonuses;
  }
  return teamBonusesState
}

const resetTeamBonuses = (teamBonusesState, action) => {
  const search = action.keepSearch ? teamBonusesState.search : initTeamBonuses.search;
  teamBonusesState = {...initTeamBonuses, search};
  return teamBonusesState
}

// TeamBonuses Reducer
const teamBonusesReducer = createReducer(initTeamBonuses, {
  SET_TEAM_BONUSES: setTeamBonuses,
  RESET_TEAM_BONUSES: resetTeamBonuses,
})

/**
 * Payments
 **/
const initPayments = {
  total: null,
  list: [],
  payments_total: 0,
  search: {
    page: 0,
    perPage: 10,
    // status: 'pending',
    year: '',
    // periodStart: '',
    // periodEnd: '',
    advisor: null,
    brokerCode: '',
  }
}

const setPayments = (paymentsState, action) => {
  if (action.payments) {
    paymentsState = action.payments;
  }
  return paymentsState
}

const resetPayments = (paymentsState, action) => {
  const search = action.keepSearch ? paymentsState.search : initPayments.search;
  paymentsState = {...initPayments, search};
  return paymentsState
}

// Payments Reducer
const paymentsReducer = createReducer(initPayments, {
  SET_PAYMENTS: setPayments,
  RESET_PAYMENTS: resetPayments,
})

/**
 * Referral
 **/
const initReferral = {
  total: null,
  list: [],
  search: {
    page: 0,
    perPage: 10,
    order: 'desc',
    orderBy: 'referral_date',
    referrer: null,
    referee: null,
    referralDate: null,
    expirationDate: null,
  }
}

const setReferral = (referralState, action) => {
  if (action.referral) {
    referralState = action.referral;
  }
  return referralState
}

const resetReferral = (referralState, action) => {
  const search = action.keepSearch ? referralState.search : initReferral.search;
  referralState = {...initReferral, search};
  return referralState
}

// Referral Reducer
const referralReducer = createReducer(initReferral, {
  SET_REFERRAL: setReferral,
  RESET_REFERRAL: resetReferral,
})

/**
 * My Referral
 **/
const initMyReferral = {
  total: null,
  list: [],
  search: {
    page: 0,
    perPage: 10,
  }
}

const setMyReferral = (myReferralState, action) => {
  if (action.myReferral) {
    myReferralState = action.myReferral;
  }
  return myReferralState
}

const resetMyReferral = (myReferralState, action) => {
  const search = action.keepSearch ? myReferralState.search : initMyReferral.search;
  myReferralState = {...initMyReferral, search};
  return myReferralState
}

// My Referral Reducer
const myReferralReducer = createReducer(initMyReferral, {
  SET_MY_REFERRAL: setMyReferral,
  RESET_MY_REFERRAL: resetMyReferral,
})

/**
 * Team Applications
 **/
const initTeamApplications = {
  total: null,
  list: [],
  search: {
    page: 0,
    perPage: 10,
    advisor: null,
    brokerCode: '',
    status: '0',
  }
}

const setTeamApplications = (teamApplicationsState, action) => {
  if (action.teamApplications) {
    teamApplicationsState = action.teamApplications;
  }
  return teamApplicationsState
}

const resetTeamApplications = (teamApplicationsState, action) => {
  const search = action.keepSearch ? teamApplicationsState.search : initTeamApplications.search;
  teamApplicationsState = {...initTeamApplications, search};
  return teamApplicationsState
}

// Team Applications Reducer
const teamApplicationsReducer = createReducer(initTeamApplications, {
  SET_TEAM_APPLICATIONS: setTeamApplications,
  RESET_TEAM_APPLICATIONS: resetTeamApplications,
})



/**
 * root
 **/
// App Reducer
const appReducer = combineReducers({
  sess: sessReducer,
  user: userReducer,
  advisors: advisorsReducer,
  corporations: corporationsReducer,
  sales: salesReducer,
  team: teamReducer,
  performance: performanceReducer,
  myPerformance: myPerformanceReducer,
  doc: docReducer,
  myDoc: myDocReducer,
  teams: teamsReducer,
  rates: ratesReducer,
  allFyc: allFycReducer,
  teamAllFYC: teamAllFYCReducer,
  myAllFYC: myAllFYCReducer,
  allFycSummary: allFycSummaryReducer,
  fyc: fycReducer,
  teamFYC: teamFYCReducer,
  myFYC: myFYCReducer,
  fycSummary: fycSummaryReducer,
  bonuses: bonusesReducer,
  teamBonuses: teamBonusesReducer,
  payments: paymentsReducer,
  referral: referralReducer,
  myReferral: myReferralReducer,
  teamApplications: teamApplicationsReducer,
})

export default appReducer;